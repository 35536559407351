<template>
    <div class="header-widget" :class="hideOnMobile ? 'hide-on-mobile' : ''">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        hideOnMobile: {
            type: Boolean,
            default: false
        },
    },
}
</script>