<template>
    <div>
        <Header>
            <Heading1 v-if="error.statusCode === 404">{{ error.title || "404 Not Found" }}</Heading1>
            <Heading1 v-else>An error occurred</Heading1>
        </Header>
        <div class="container margin-bottom-100">
            <div class="row">
                <div class="col-xl-12">
                    <section v-if="error.statusCode === 404" id="not-found"
                        class="center margin-top-50 margin-bottom-25">
                        <!-- <h2>404 -->
                        <!-- <i class="icon-line-awesome-question-circle"></i> -->
                        <!-- </h2> -->
                        <p v-if="!error.message">Sorry, we can't find the page you're looking for.</p>
                        <p v-if="error.message">{{ error.message }}</p>
                    </section>
                    <section v-else id="not-found" class="center margin-top-50 margin-bottom-25">
                        <h2>Error <i class="icon-line-awesome-question-circle"></i></h2>
                        <p>Oops, something went wrong.</p>
                    </section>

                    <SearchBar></SearchBar>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '~/components/base/Header.vue';
import Heading1 from '../components/base/Heading1.vue';
import SearchBar from '@/components/homepage/SearchBar.vue';

export default {
    props: ['error'],
    layout: 'error', // you can set a custom layout for the error page
    components: {
        Header,
        Heading1,
        SearchBar
    },
    mounted() {
        this.$sentry.captureException(this.error);
        this.$store.dispatch("base/getHomePageListings");

    },

}
</script>