// middleware/redirect.js

export default function ({ req, redirect, error }) {
    const path = req?.url;
    if (!path)
        return;

    const pathsToThrow404 = ['/jewish-business-directory', '/products', '/vehicles'];
    if (pathsToThrow404.includes(path)) {
        error({ statusCode: 404, message: 'Page not found' });
        return;
    }

    if (path === '/jewish-jobs') {
        return redirect(301, '/all');
    }

    // Remove 'jewish-jobs' from the URL if it exists
    const newPath = path.replace(/\/jewish-jobs(\/|$)/, '/');

    if (newPath === '/g') {
        return redirect(301, '/all');
    }

    // Results pages sub category
    if (/^(.+)\/dmc_\d+_dc_\d+/.test(newPath)) {
        const newPathWithoutParams = newPath.split('/').slice(0, -1).join('/');
        return redirect(301, newPathWithoutParams);
    }

    // Results pages main category
    if (/^(.+)\/dmc_\d+/.test(newPath)) {
        const newPathWithoutParams = newPath.split('/').slice(0, -1).join('/');
        return redirect(301, newPathWithoutParams);
    }

    // Item page - First level
    let matches = newPath.match(/^\/item-d(\d+)$/);
    if (matches) {
        return redirect(301, `/item/item/${matches[1]}`);
    }

    // Item page - Second level
    matches = newPath.match(/^\/([^/]+)\/([^/]+)\/item-d(\d+)$/);
    if (matches && matches[1] && matches[2] && matches[3]) {
        return redirect(301, `/${matches[1]}/${matches[2]}/item/${matches[3]}`);
    }

    // Item page - Third level
    matches = newPath.match(/^\/([^/]+)\/item-d(\d+)$/);
    if (matches && matches[1] && matches[2]) {
        return redirect(301, `/${matches[1]}/item/${matches[2]}`);
    }

    // If none of the conditions match, the middleware will simply continue without redirecting.
}
