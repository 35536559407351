import {
  getJobsList,
  deleteJobAdById,
  restoreAd,
  deleteArchiveJobAdById,
} from "../../services/api/jobs";

export const NAMESPACE = "dashboard/manageAds";

export const MUTATIONS = {
  SET_JOBS_LIST: "setJobsList",
};

export const ACTIONS = {
  GET_JOBS_LIST: "getJobsList",
  DELETE_JOB_BY_ID: "deleteJobById",
  DELETE_ARCHIVE_JOB_BY_ID: "deleteArchiveJobById",
  RESTORE_AD: "restoreAd",
};

const store = {
  state() {
    return {
      jobsList: [],
    };
  },
  mutations: {
    [MUTATIONS.SET_JOBS_LIST](state, jobsList) {
      state.jobsList = jobsList;
    },
  },
  actions: {
    [ACTIONS.GET_JOBS_LIST]({ commit }, data) {
      return getJobsList(data).then((res) => {
        const { data: { recordset: jobsList } = {} } = res;
        commit(MUTATIONS.SET_JOBS_LIST, jobsList);
        return jobsList;
      });
    },

    [ACTIONS.DELETE_JOB_BY_ID]({ dispatch }, { postId, userId }) {
      return deleteJobAdById({ postId, archive: 0 }).then(() =>
        dispatch(ACTIONS.GET_JOBS_LIST, { userId })
      );
    },
    [ACTIONS.DELETE_ARCHIVE_JOB_BY_ID]({ dispatch }, { postId, userId }) {
      return deleteArchiveJobAdById({ postId }).then(() =>
        dispatch(ACTIONS.GET_JOBS_LIST, { userId })
      );
    },
    [ACTIONS.RESTORE_AD]({ dispatch }, { postId, userId }) {
      return restoreAd({ postId }).then((res) => {
        dispatch(ACTIONS.GET_JOBS_LIST, { userId });
        return res;
      });
    },
  },
};

export default store;
