import Vue from "vue";
import moment from "moment";

Vue.filter("capitalize", (val) => val.toUpperCase());
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("MM/DD/YYYY");
  }
});
Vue.filter("truncate", function (text, stop, clamp = "") {
  if (!text) return "";
  text = text.trim();
  var separator = " ";
  if (text.length <= stop) return text;
  return text.substr(0, text.lastIndexOf(separator, stop)) + clamp + " ...";

  // return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
});
Vue.filter("stripHTML", function (value) {
  return value.replace(/<\/?[^>]+>/gi, " ");
});
Vue.filter('formatSalary', function (value) {
  if (!value) return '';
  // Use parseFloat to ensure we're working with a number and preserve any decimals
  value = parseFloat(value);
  if (value >= 1000) {
    // Divide by 1000; keep original decimal precision for the division result
    const dividedValue = value / 1000;
    // Check if the result is an integer; if so, convert to string without decimals
    // Otherwise, convert to string with the original number of decimal places
    return (dividedValue % 1 === 0 ? dividedValue.toString() : dividedValue) + 'K';
  } else {
    // For values less than 1000, check if it's an integer
    // If it is, display without decimal point; if not, display original value
    return value % 1 === 0 ? value.toString() : value;
  }
});



Vue.filter('formatNumber', function (value) {
  if (!value) return '';
  value = parseInt(value);
  if (value >= 10000) {
    return Math.round(value / 1000) + 'K';
  }
  return value.toString();
});
