import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {"position":"top-center","iconPack":"material","closeOnSwipe":true,"duration":3000})

const globals = [{"name":"userexist","message":"It seems like you have an account.","options":{"type":"info","duration":3000,"action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"incorrectpassword","message":"User or passwrods are incorrect.","options":{"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }},{"text":"Forgot your password?","href":"\u002Flogin?mode=forget"}]}},{"name":"loginsuccessful","message":"Successfully Logged In","options":{"type":"success","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"subscribedSuccessful","message":"Thanks for joining our mailing list!","options":{"type":"success","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"subscribedError","message":"Oops, something went wrong! We apologize for the inconvenience.","options":{"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"registersuccessful","message":"Your account was successfully created!","options":{"type":"success","duration":3000,"action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"imagesize","message":"Image must be smaller than 5MB!","options":{"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"resumeSize","message":"File must be smaller than 10MB!","options":{"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"resumeFormat","message":"File must be in Pdf\u002FDoc\u002FDocx format","options":{"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"resumeUploadSucessful","message":"The resume file was successfully uploaded","options":{"type":"success","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"resumeUploadFailed","message":"Uploading resume failed","options":{"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"imageFormat","message":"Image must be in png\u002Fjpeg format","options":{"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"maxResumeFile","message":"Maximum of 5 resume files allowed per user","options":{"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"userupdated","message":"Successfully updated","options":{"duration":3000,"type":"success","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"updateFailed","message":"Update user failed","options":{"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"updateAdFailed","message":"Job Ad update failed","options":{"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"emailSent","message":"Thank you for contacting us! We have successfully received your message. Our team will review your inquiry and get back to you as soon as possible.","options":{"type":"success","duration":4000,"action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"emailNotSent","message":"Oops, Something went wrong","options":{"duration":3000,"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"wentWrong","message":"Oops, Something went wrong","options":{"type":"error","duration":3000,"action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"resumeSent","message":"Your application has been successfully sent to the employer.","options":{"duration":3000,"type":"success","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"messageSentToCandidate","message":"Your message has been successfully sent to the candidate.","options":{"duration":3000,"type":"success","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"fillRequiredFields","message":"Please complete all required fields","options":{"duration":3000,"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"fillMessage","message":"Please enter a message","options":{"duration":3000,"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"candidateDeleted","message":"The candidate was removed successfully","options":{"duration":3000,"type":"success","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"activateAdFailed","message":"Ad Activation Failed","options":{"duration":3000,"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"emailDoesNotExist","message":"The email address doesn't exist ","options":{"duration":3000,"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"resetEmailSent","message":"A reset password link was successfully sent to your email.","options":{"duration":3000,"type":"success","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"resetPassword","message":"Your password has been reset successfully. You will now be redirected back to the login page.","options":{"duration":3000,"type":"success","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"tokenInvalid","message":"The reset link has expired. Please ask for a reset link again.","options":{"duration":3000,"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"activateAdTokenInvalid","message":"The activation link is invalid or has expired. Please request a new activation link.","options":{"duration":4000,"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"robotAdNotFound","message":"The ad is deleted. You can place a new ad by clicking on the 'Post Your Ad' button in the top right corner of this page.","options":{"duration":4000,"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"itemNotFound","message":"Job ad was not found!","options":{"duration":3000,"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}},{"name":"appliedAlready","message":"You have already applied today. Please try again tomorrow.","options":{"duration":3000,"type":"error","action":[{"text":"Dismiss","onClick":(e, toastObject) => {
          toastObject.goAway(0);
        }}]}}]
if(globals) {
  globals.forEach(global => {
    Vue.toasted.register(global.name, global.message, global.options)
  })
}

export default function (ctx, inject) {
  inject('toast', Vue.toasted)
}
