<template>
    <li :class="active ? 'active' : ''">
        <nuxt-link :to="to">
            <slot>

            </slot>
        </nuxt-link>
        <slot name="child"></slot>
    </li>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false
        },
        to: {
            type: String,
            default: "#"
        }
    },
}
</script>
