<template>
    <div class="header-notifications-dropdown">
        <div class="header-notifications-headline">
            <h4>{{ name }}</h4>
            <button class="mark-as-read ripple-effect-dark" data-tippy-placement="left" data-tippy=""
                data-original-title="Mark all as read">
                <CheckSquare></CheckSquare>
            </button>
        </div>

        <div class="header-notifications-content">
            <div class="header-notifications-scroll" data-simplebar="init" style="height: 270px;">
                <div class="simplebar-track vertical" style="visibility: visible;">
                    <div class="simplebar-scrollbar" style="visibility: visible; top: 0px; height: 192px;"></div>
                </div>
                <div class="simplebar-track horizontal" style="visibility: visible;">
                    <div class="simplebar-scrollbar" style="visibility: visible; left: 0px; width: 25px;"></div>
                </div>
                <div class="simplebar-scroll-content" style="padding-right: 17px; margin-bottom: -34px;">
                    <div class="simplebar-content" style="padding-bottom: 17px; margin-right: -17px;">
                        <ul>
                            <!-- Notification -->
                            <li class="notifications-not-read">
                                <a href="dashboard-manage-candidates.html">
                                    <span class="notification-icon"><i class="icon-material-outline-group"></i></span>
                                    <span class="notification-text">
                                        <strong>Michael Shannah</strong> applied for a job <span class="color">Full
                                            Stack Software Engineer</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import CheckSquare from "~/assets/icons/check-square.svg"
export default {
    components: {
        CheckSquare,
    },
    props: {
        name: {
            type: String,
            default: 'Title'
        },
    },
}
</script>

<style lang="scss" scoped>

</style>