import axios from "axios";
import { Exception } from "sass";
import { getField, updateField } from "vuex-map-fields";

// import global from './global.js';
var dataIndex = {
  jobs: 0,
  states: 1,
  cities: 2,
  mainCategories: 3,
  categories: 4,
  mainCategorySEO: 5,
  categorySEO: 6,
  // topLocations: 5,
  // locationSEO: 6,
  stateSEO: 7,
  citySEO: 8,
  //premiumJobs: 9
};

//helper functions

function isPointInGeometry(lng, lat, locationData) {
  //var turf = require("@turf/turf");
  var isWithinGeometry = false;
  if (!lng || !lat) return false;
  var point = turf.point([lng, lat]);
  var geometries = locationData;
  for (var i = 0; i < geometries.length; i++) {
    try {
      var coordinates = geometries[i];

      // Treat each geometry as a Polygon, because even LineStrings and Points are buffered into Polygons
      var poly = turf.polygon(coordinates);
      isWithinGeometry = turf.booleanPointInPolygon(point, poly, { ignoreBoundary: true });

      if (isWithinGeometry) return true;
    } catch (ex) {
      console.log("Error in processing geometry:", ex);
    }
  }

  return isWithinGeometry;
}



function getDefaultState() {
  return {
    jobs: [],
    favouriteJobs: [],
    appliedJobs: [],
    singleJob: {},
    isJobsListingsLoaded: false,
    isSingeJobLoaded: false,
    //premiumJobs: [],
    filter: {
      stateId: 0,
      cityId: [],
      mainCategoryId: 0,
      categoryId: [],
      freelance: 0,
      partTime: 0,
      fullTime: 0,
      hybrid: 0,
      internship: 0,
      temporary: 0,
      fromHome: 0,
      freeText: "",
      sortBy: { value: "type", direction: "desc" },
      // radius: 1,
      // location: [],
    },
    filterDefault: {
      stateId: 0,
      cityId: [],
      mainCategoryId: 0,
      categoryId: [],
      freelance: 0,
      partTime: 0,
      fullTime: 0,
      hybrid: 0,
      internship: 0,
      temporary: 0,
      fromHome: 0,
      freeText: "",
      // radius: 0,
      // location: [],
    },
    filterDefinition: [
      {
        name: "mainCategoryId",
        multiple: false,
        server: true,
        resetSubCategory: true,
        subCategory: "categoryId",
      },
      {
        name: "categoryId",
        multiple: true,
        server: false,
        resetSubCategory: false,
        subCategory: "",
      },
      {
        name: "stateId",
        multiple: false,
        server: true,
        resetSubCategory: true,
        subCategory: "cityId",
      },
      {
        name: "cityId",
        multiple: true,
        server: false,
        resetSubCategory: false,
        subCategory: "",
      },
      {
        name: "freelance",
        multiple: false,
        server: false,
        resetSubCategory: false,
        subCategory: "",
      },
      {
        name: "partTime",
        multiple: false,
        server: false,
        resetSubCategory: false,
        subCategory: "",
      },
      {
        name: "fullTime",
        multiple: false,
        server: false,
        resetSubCategory: false,
        subCategory: "",
      },
      {
        name: "hybrid",
        multiple: false,
        server: false,
        resetSubCategory: false,
        subCategory: "",
      },
      {
        name: "internship",
        multiple: false,
        server: false,
        resetSubCategory: false,
        subCategory: "",
      },
      {
        name: "temporary",
        multiple: false,
        server: false,
        resetSubCategory: false,
        subCategory: "",
      },
      {
        name: "fromHome",
        multiple: false,
        server: false,
        resetSubCategory: false,
        subCategory: "",
      },
      {
        name: "freeText",
        multiple: false,
        server: true,
        resetSubCategory: false,
        subCategory: "",
      },
      // {
      //   name: "radius",
      //   multiple: false,
      //   server: false,
      //   resetSubCategory: false,
      //   subCategory: "",
      // },
      // {
      //   name: "location",
      //   multiple: false,
      //   server: false,
      //   resetSubCategory: false,
      //   subCategory: "",
      // },
    ],

    stateIdData: [],
    mainCategoryIdData: [],
    cityIdData: [],
    categoryIdData: [],
    freelanceData: [{ name: "Freelance", id: 1 }],
    fromHomeData: [{ name: "Remote", id: 1 }],
    partTimeData: [{ name: "Part Time", id: 1 }],
    fullTimeData: [{ name: "Full Time", id: 1 }],
    hybridData: [{ name: "Hybrid", id: 1 }],
    internshipData: [{ name: "Internship", id: 1 }],
    temporaryData: [{ name: "Temporary", id: 1 }],

    metaTags: {
      title:
        "Jewish Jobs on Macher: The Largest Jewish Jobs Website in the US",
      description:
        "The largest jewish jobs website in NYC, Brooklyn, Boro Park, Five Towns, Crown Heights, Woodmere, Monsey, Lakewood and more.",
      socialTitle: "",
      socialDescription: "",
      canonical: "",
      twitter: "@FrumJewishJobs",
      siteName: "Macher",
      categoryName: "",
      location: "",
    },
    currentPage: 1,
    perPage: 25,
    breadCrumbs:
      [{
        name: "Home",
        url: "/"
      },
      {
        name: "Jobs",
        url: "/all"
      },
      ],
  }
}


export const state = () => ({
  ...getDefaultState()
});

export const mutations = {
  destroyJobsListings(state) {
    Object.assign(state, getDefaultState());
  },

  setJobs(state, data) {
    state.jobs = data;
  },
  setJobsListingsLoaded(state, data) {
    state.isJobsListingsLoaded = data;
  },

  setFavouriteJobs(state, data) {
    state.favouriteJobs = data;
  },
  setAppliedJobs(state, data) {
    state.appliedJobs = data;
  },
  setSingleJob(state, data) {
    state.singleJob = data;
  },

  setSingleJobLoaded(state, data) {
    state.isSingeJobLoaded = data;
  },
  setStateData(state, data) {
    state.stateIdData = data;
  },

  setCityData(state, data) {
    state.cityIdData = data;
  },

  setMainCategoryData(state, data) {
    state.mainCategoryIdData = data;
  },

  setCategoryData(state, data) {
    state.categoryIdData = data;
  },
  // setTopLocationsData(state, data) {
  //   state.topLocationsData = data;
  // },
  // setLocationData(state, data) {
  //   state.locationData = data;
  // },
  // setpremiumJobs(state, data) {
  //   state.premiumJobs = data;
  // },

  setMetaTags(state, metaTags) {
    state.metaTags = Object.assign({}, state.metaTags, metaTags);
  },

  setFilter(state, filter) {
    state.filter = Object.assign({}, state.filter, filter);
  },

  updateFilter(state, field) {
    updateField(state.filter, field);
  },

  resetFilter(state) {
    Object.assign(state.filter, state.filterDefault);
  },

  setcurrentPage(state, currentPage) {
    state.currentPage = currentPage;
  },

  setLocation(state, locationData) {
    state.filter.location = locationData;
  },

  setperPage(state, perPage) {
    state.perPage = perPage;
  },
  // async setLocation(state, locationName) {
  //   try {
  //     console.log(state.filter.location);
  //     // console.log(await this.$global.getLocationPolygon(locationName));
  //     state.filter.location = await this.$global.getLocationPolygon(locationName);
  //     console.log(state.filter);
  //   } catch (error) {
  //     console.error('Error setting location:', error);
  //   }
  // },

  setBreadCrumbs(state, breadCrumbs) {
    state.breadCrumbs = breadCrumbs;
  },
};

export const getters = {

  isJobsListingsLoaded: (state) => {
    return state.isJobsListingsLoaded;
  },

  isSingeJobLoaded: (state) => {
    return state.isSingeJobLoaded;
  },

  filteredJobs: (state) => {
    var filter = state.filter;
    return state.jobs
      .filter((x) => {
        const cityCheck = filter.cityId.length === 0 ||
          filter.cityId.indexOf(x.cityId) > -1;

        const categoryCheck = filter.categoryId.length === 0 ||
          filter.categoryId.indexOf(x.categoryId) > -1;

        // Job Type checks: modified to use && logic
        // For each job type, the condition is true if the filter is not applied or if the job matches the filter
        const freelanceCheck = !filter.freelance || (filter.freelance && x.freelance);
        const fullTimeCheck = !filter.fullTime || (filter.fullTime && x.fullTime);
        const partTimeCheck = !filter.partTime || (filter.partTime && x.partTime);
        const fromHomeCheck = !filter.fromHome || (filter.fromHome && x.fromHome);
        const temporaryCheck = !filter.temporary || (filter.temporary && x.temporary);
        const hybridCheck = !filter.hybrid || (filter.hybrid && x.hybrid);


        // Combine all checks using &&
        return cityCheck && categoryCheck &&
          freelanceCheck && fullTimeCheck && partTimeCheck &&
          fromHomeCheck && temporaryCheck && hybridCheck;
      })
      .sort((a, b) => {
        var direction = state.filter.sortBy.direction;
        var sortBy = state.filter.sortBy.value;
        if (sortBy == "type") {
          if (b.type > a.type) return 1;
          else if (a.type > b.type) return -1
          else {
            // If types are equal, sort by date
            return new Date(b.date) - new Date(a.date);
          }
        }
        else {
          if (direction == "desc") return new Date(b.startDate) - new Date(a.startDate);
          else return new Date(a.startDate) - new Date(b.startDate);
        }
      });
  },
  filteredJobsSliced: (state, getters) => {
    return getters.filteredJobs.slice(
      state.filter.start,
      state.perPage * state.currentPage
    );
  },
  // premiumJobs: state => {
  //   var filter = state.filter;
  //   let jobs = [];
  //   let premiumJobs = [];

  //   Object.assign(jobs, state.premiumJobs);
  //   jobs.sort(function () { return 0.5 - Math.random() });

  //   //try to find 3 jobs from the main category
  //   premiumJobs = jobs
  //     .filter(x => {
  //       return (
  //         filter.mainCategoryId == 0 ||
  //         filter.mainCategoryId == x.mainCategoryId
  //       );
  //     })

  //   //if there are not enough, combine it with other premium ads
  //   if (premiumJobs.length < 3) {
  //     if (premiumJobs.length > 0)
  //       premiumJobs.push(...jobs);
  //     else
  //       premiumJobs = jobs;
  //   }

  //   return premiumJobs.slice(0, 3);
  // },

  getFilter: (state) => {
    return getField(state.filter);
  },
  sum: (state, getters) => {
    return getters.filteredJobs.length;
  },
  currentPage: (state) => {
    return state.currentPage;
  },
  perPage: (state) => {
    return state.perPage;
  },
  metaTags: (state) => {
    return state.metaTags;
  },
  breadCrumbs: (state) => {
    return state.breadCrumbs;
  },
  singleJob: (state) => {
    return state.singleJob;
  },
};

export const actions = {
  async getJobs({ commit, state, dispatch }) {
    //set free text if it was porvided by query string
    // Extract only mainCategoryId and freeText from the filter state
    const { mainCategoryId, freeText, stateId } = state.filter;

    // Create a new object with only the required properties
    const filterToSend = { mainCategoryId, stateId, freeText };
    return axios
      .post(process.env.fullBaseApi + "/jobs/jobsList", {
        filter: filterToSend,
      })
      .then((jobs) => {
        if (!jobs.data || !jobs.data.data) return;
        var data = jobs.data.data.recordsets;

        // data[dataIndex.states].unshift({ id: 0, name: "All States" });
        // data[dataIndex.mainCategories].unshift({ id: 0, name: "All Categories" });
        commit("setJobs", data[dataIndex.jobs]);
        commit("setStateData", data[dataIndex.states]);
        commit("setCityData", data[dataIndex.cities]);
        commit("setMainCategoryData", data[dataIndex.mainCategories]);
        commit("setCategoryData", data[dataIndex.categories]);
        commit("setcurrentPage", 1);
        dispatch("setMetaTags", data);

      })
      .catch(error => {
        return Promise.reject(error);
      });
  },

  async getJobsMobile({ commit, state, dispatch }) {
    const { mainCategoryId, freeText, stateId } = state.filter;

    // Create a new object with only the required properties
    const filterToSend = { mainCategoryId, stateId, freeText };
    return axios
      .post(process.env.fullBaseApi + "/jobs/jobsListMobile", {
        filter: filterToSend,
      })
      .then((jobs) => {
        if (!jobs.data || !jobs.data.data) return;
        var data = jobs.data.data.recordsets;
        commit("setJobs", data[dataIndex.jobs]);
        commit("setcurrentPage", 1);
        dispatch("setMetaTags", data);

        // commit("setStateData", data[dataIndex.states]);
        // commit("setMainCategoryData", data[dataIndex.mainCategories]);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },

  async getJobsQueryString({ commit, dispatch }, data) {
    commit("resetFilter");
    var route = data.route;
    // var location = data.qstring.qstring.location;
    // if (location) await dispatch("setLocation", location);
    var freeText = data.qstring.qstring.freeText;
    var type = data.qstring.qstring.type;

    return axios
      .post(process.env.fullBaseApi + "/jobs/jobsQueryString", data.qstring)
      .then((jobs) => {
        if (!jobs.data || !jobs.data.data) return;

        var data = jobs.data.data.recordsets;
        // data[dataIndex.states].unshift({ id: 0, name: "All States" });
        // data[dataIndex.mainCategories].unshift({ id: 0, name: "All Categories" });
        if (type == "remote") commit("setFilter", { fromHome: true });
        commit("setJobsListingsLoaded", true);

        commit("setJobs", data[dataIndex.jobs]);

        commit("setStateData", data[dataIndex.states]);
        commit("setCityData", data[dataIndex.cities]);
        commit("setMainCategoryData", data[dataIndex.mainCategories]);
        commit("setCategoryData", data[dataIndex.categories]);
        // commit("setTopLocationsData", data[dataIndex.topLocations]);
        // commit("setLocationData", data[dataIndex.locationSEO]);
        // commit("setpremiumJobs", data[dataIndex.premiumJobs]);
        dispatch("setMetaTags", data);
        if (freeText) commit("setFilter", { freeText: freeText });
        //dispatch("setMetaTags", { data: data, route: route });
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },


  async getSubCategories({ commit, dispatch }, data) {
    return axios
      .post(process.env.fullBaseApi + "/jobs/categories", data)
      .then((categories) => {
        if (!categories.data.data.recordset || !categories.data.data.recordset) return;
        commit("setCategoryData", categories.data.data.recordset);
      });
  },

  async getCities({ commit, dispatch }, data) {
    return axios
      .post(process.env.fullBaseApi + "/jobs/cities", data)
      .then((categories) => {
        if (!categories.data.data.recordset || !categories.data.data.recordset) return;
        commit("setCityData", categories.data.data.recordset);
      });
  },
  async setLocation({ commit }, locationName) {
    try {
      const locationData = await this.$global.getLocationPolygon(locationName);
      commit('setLocation', locationData);
    } catch (error) {

      console.error('Error setting location:', error);
      return Promise.reject(error);
    }
  },


  async getSingleJob({ commit, dispatch }, data) {
    return axios
      .post(process.env.fullBaseApi + "/jobs/singleJob", data)
      .then((singleJob) => {
        if (!singleJob.data || !singleJob.data.data) return;
        var data = singleJob.data.data.recordset;
        if (!data.length) return;

        commit("setSingleJob", data[0]);
        dispatch("setMetaTagsSingle", data);
        commit("setSingleJobLoaded", true);

        // commit("setStateData", data[dataIndex.states]);
        //dispatch("setMetaTags", { data: data, route: route });
      });
  },

  async insertJobApplicant({ commit, dispatch }, data) {
    //set free text if it was porvided by query string
    return axios.post(process.env.fullBaseApi + "/jobs/insertJobApplicant", {
      params: data.applicant,
    });
  },

  setMetaTagsSingle({ commit }, data) {
    var data = data[0];

    var breadcrumbs = [
      {
        name: "Home",
        url: "/",
      },
      {
        name: "Jobs",
        url: "/all",
      },
    ];

    var metaTags = {
      title: "",
      description: "",
      socialTitle: "",
      socialDescription: "",
      canonical: "",
      categoryName: "",
      location: "",
    };

    var title = "";
    var description;
    var qstring1, qstring2, qstring3;
    var canonical = "";

    //grap the information from db result

    qstring1 = data.mainCategoryQstring;
    breadcrumbs.push({ name: data.mainCategoryName, url: "/" + qstring1 });

    qstring2 = data.categoryQstring;
    breadcrumbs.push({
      name: data.categoryName,
      url: "/" + qstring1 + "/" + qstring2,
    });

    qstring3 = "item/" + data.id;
    // breadcrumbs.push({
    //   name: "item",
    //   url: "/" + qstring1 + "/" + qstring2 + "/" + qstring3,
    // });

    //create canonical by params
    canonical = process.env.baseUrl + breadcrumbs[breadcrumbs.length - 1].url;

    title = data.title;

    const cleanedDescription = data.description.replace(/<\/?[^>]+(>|$)/g, " ").replace(/\s+/g, ' ').trim();

    description = cleanedDescription.substring(0, 200);

    //final: set the tags
    metaTags.title = "Macher Jewish Jobs - " + title;
    metaTags.description = "Macher Jewish Jobs - " + description;
    metaTags.socialTitle = title;
    metaTags.socialDescription = description;
    metaTags.canonical = canonical;
    commit("setMetaTags", metaTags);
    commit("setBreadCrumbs", breadcrumbs);
  },

  setMetaTags({ commit, state }, data) {
    var breadcrumbs = [
      {
        name: "Home",
        url: "/",
      },
      {
        name: "Jobs",
        url: "/all",
      },
    ];

    var metaTags = {
      title: "",
      description: "",
      socialTitle: "",
      socialDescription: "",
      canonical: "",
      categoryName: "",
      location: "",
    };

    var params = [];

    var title = "";
    var description = "";
    var qstring1, qstring2;
    var location = "";
    var canonical = "";

    //grap the information from db result
    var mainCategory = data[dataIndex.mainCategorySEO];
    if (mainCategory && mainCategory[0]) {
      title = mainCategory[0].title;
      description = mainCategory[0].description;
      qstring1 = mainCategory[0].qstring;
      breadcrumbs.push({ name: title, url: "/" + qstring1 });
      commit("setFilter", { mainCategoryId: mainCategory[0].mainCategoryId });
    }

    var category = data[dataIndex.categorySEO];
    if (category && category[0]) {
      title = category[0].title;
      description = category[0].description;
      qstring2 = category[0].qstring;
      breadcrumbs.push({
        name: title,
        url: "/" + qstring1 + "/" + qstring2,
      });
      commit("setFilter", { categoryId: [category[0].categoryId] });
    }

    //grap the information from db result
    var state1 = data[dataIndex.stateSEO];
    if (state1 && state1[0]) {
      location = " in " + state1[0].title;
      commit("setFilter", { stateId: state1[0].stateId });
    }

    var city = data[dataIndex.citySEO];
    if (city && city[0]) {
      location = " in " + city[0].title;
      params.push({ name: "cityId", id: city[0].cityId });
      commit("setFilter", { cityId: [city[0].cityId] });
    }


    var type = "";

    if (state.filter.fromHome) {
      type = "Remote";
      params.push({ name: "type", id: "remote" });
    }


    //create canonical by params
    canonical = process.env.baseUrl + breadcrumbs[breadcrumbs.length - 1].url;
    params.forEach((param, index) => {
      if (index == 0) canonical += "?";
      else canonical += "&";
      canonical += param.name + "=" + param.id;
    });

    //final: set the tags
    metaTags.title = "Jewish " + type + title + " Jobs" + location + " on Macher";
    metaTags.description =
      "Search here for thousands of " + type + " jewish jobs - " +
      description +
      location +
      " and more on Macher, the largest jewish jobs website";
    metaTags.socialTitle = type + title + " Jobs";
    metaTags.socialDescription = type + description + " Jobs";
    metaTags.canonical = canonical;

    metaTags.categoryName = title;
    metaTags.location = location;
    commit("setMetaTags", metaTags);
    commit("setBreadCrumbs", breadcrumbs);
  },

  async resetFilter({ commit }) {
    commit("resetFilter");
  },

  async updateStatistics({ commit, dispatch }, data) {
    //set free text if it was porvided by query string
    return axios.post(process.env.fullBaseApi + "/jobs/updateStatistics", {
      params: data.data,
    }).catch((error) => {
      return Promise.reject(error)
    });
  },

  //for dashboard/favourites
  async getFavouriteJobs({ commit, state }) {
    if (process.client) {

      // Retrieve favorite job IDs from localStorage
      const favoriteJobIds = localStorage.getItem('bookmarks');

      // Check if favorite jobs are stored in localStorage
      if (!favoriteJobIds) {
        console.log('No favorite jobs found');
        return;
      }

      const jobIdsArray = favoriteJobIds.split(',').map(Number);
      // Call the backend API to get favorite jobs
      return axios
        .post(process.env.fullBaseApi + "/jobs/getFavouriteJobs", {
          jobIds: jobIdsArray
        })
        .then((response) => {
          if (!response.data || !response.data.data) return;
          var data = response.data.data.recordsets;

          commit("setFavouriteJobs", data[0]); // Assuming the jobs are in the first recordset
        })
        .catch((error) => {
          console.error('Error fetching favorite jobs:', error);
          return Promise.reject(error)
        });
    }
  },


  //for dashboard/applied jobs
  async getAppliedJobs({ commit, state }, { userId }) {
    return axios
      .post(process.env.fullBaseApi + "/jobs/getAppliedJobs", { userId })
      .then((response) => {
        if (!response.data || !response.data.data) return;
        var data = response.data.data.recordsets;
        commit("setAppliedJobs", data[0]); // Assuming the jobs are in the first recordset
      })
      .catch((error) => {
        console.error('Error fetching applied jobs:', error);
        return Promise.reject(error)
      });

  },


};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
