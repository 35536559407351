import { render, staticRenderFns } from "./HeaderNotificationsDropdown.vue?vue&type=template&id=729b0810&scoped=true&"
import script from "./HeaderNotificationsDropdown.vue?vue&type=script&lang=js&"
export * from "./HeaderNotificationsDropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "729b0810",
  null
  
)

export default component.exports