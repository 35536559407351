// plugins/sentryErrorHandler.js

export default (context, inject) => {
    const { $sentry, $toast } = context.app;

    function handleExceptionWithSentry(error, message = "Sorry, something went wrong. We're working to fix the issue.") {
        // Capture the exception using Sentry
        if ($sentry) {
            $sentry.captureException(error);
        }

        // Display a toast to the user
        if ($toast) {
            $toast.error(message, {
                duration: 10000,
                action: [
                    {
                        text: "Dismiss",
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                ]
            });
        }
    }

    // Inject the handler function into the Vue instance
    inject('handleExceptionWithSentry', handleExceptionWithSentry);
};
