import { render, staticRenderFns } from "./Heading1.vue?vue&type=template&id=018c9d34&scoped=true&"
var script = {}
import style0 from "./Heading1.vue?vue&type=style&index=0&id=018c9d34&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018c9d34",
  null
  
)

export default component.exports