import {
  getInvoicesByUserId
} from "../../services/api/invoices";

export const NAMESPACE = 'dashboard/invoices'

export const MUTATIONS = {
  SET_INVOICES_BY_USER_ID: 'setInvoicesByUserId',
  SET_LOADING: 'setLoading'
};
export const ACTIONS = {
  GET_INVOICES_BY_USER_ID: 'getInvoicesByUserId',
};

const store = {
  state() {
    return {
      invoices: [],
      loading: false
    }
  },
  mutations: {
    [MUTATIONS.SET_INVOICES_BY_USER_ID](state, invoices) {
      state.invoices = invoices;
    },
    [MUTATIONS.SET_LOADING](state, loading) {
      state.loading = loading;
    }
  },
  actions: {
    [ACTIONS.GET_INVOICES_BY_USER_ID]({ commit }, data) {
      commit(MUTATIONS.SET_LOADING, true);
      return getInvoicesByUserId(data).then(({ data }) => {
        const { recordset: invoices } = data;
        commit(MUTATIONS.SET_INVOICES_BY_USER_ID, invoices);
        commit(MUTATIONS.SET_LOADING, false);
        return invoices;
      })
    },
  }
};

export default store;
