<template>
    <div class="header-notifications">

        <!-- Trigger -->
        <div class="header-notifications-trigger">
            <NuxtLink :to="to || currentRoute" rel="nofollow">
                <slot name="trigger">
                    <IconBell></IconBell>
                    <span>4</span>
                </slot>
            </NuxtLink>
        </div>

        <!-- Dropdown -->
        <slot name="dropdown"></slot>
    </div>
</template>

<script>
export default {
    props: {
        to: {
            type: String,
            default: ''
        },
    },
    computed: {
        currentRoute() {
            // Use this.$route to get the current route
            return this.$route;
        },
    },
}
</script>

<style lang="scss" scoped></style>