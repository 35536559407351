<template>
    <div class="col-xl-2 col-lg-2 col-md-3">
        <div class="footer-links">
            <h3 v-if="title">{{ title }}</h3>
            <ul>
                <li v-for="link in linkList" :key="link.to">
                    <NuxtLink :to="link.to">
                        <span>{{ link.name }}</span>
                    </NuxtLink>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
        },
        linkList: {
            type: Array,
            required: true
        }
    },
}
</script>

<style lang="scss" scoped></style>