<template>
    <div class="row">
        <div class="col-md-12">
            <div class="intro-banner-search-form margin-top-95">

                <!-- Search Field -->
                <div class="intro-search-field with-autocomplete with-label">
                    <label for="autocomplete-input" class="field-title ripple-effect">What job do you want?</label>
                    <input id="autocomplete-input" v-model="freeText" type="text" placeholder="Title or Keywords"
                        class="pac-target-input" autocomplete="off">
                </div>

                <!-- Select Location -->
                <div class="intro-search-field with-autocomplete with-label">
                    <select class="selectpicker" data-selected-text-format="count" data-size="7" title="All Locations"
                        id="location" v-model="location">
                        <option v-for="item in topLocations" :value="item.longName" :key="item.id">
                            {{ item.name }} ({{ item.total }})

                        </option>
                    </select>
                    <!-- <div class="pac-container pac-logo hdpi"
                        style="display: none; width: 504px; position: absolute; left: 182px; top: 466px;"></div>
                    <label for="autocomplete-input" class="field-title ripple-effect">Where?</label>
                    <div class="input-with-icon" id="autocomplete-container">
                        <input id="autocomplete-input-location" type="text" v-model="location"
                            placeholder="City, State or ZIP" class="pac-target-input" autocomplete="off">
                        <i class="icon-material-outline-location-on"></i>
                    </div> -->
                </div>


                <!-- Button -->
                <div class="intro-search-button">
                    <button class="button ripple-effect" @click="search()">Search</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {

    data() {
        return { freeText: "", location: "" }
    },
    computed: {
        ...mapState("base", {
            topLocations: (state) => state.topLocations
        }),
        ...mapGetters({
            isMobile: "isMobile",
        }),
    },
    updated: function () {
        this.$nextTick(function () {
            //require("bootstrap-select");
            $(this.$el).find(".selectpicker").selectpicker("refresh");
            //$(".selectpicker").selectpicker("refresh");
        });
    },

    methods: {
        search: function () {
            let query = {};
            if (this.location) {
                query.location = this.location;
            }
            if (this.freeText) {
                query.freeText = this.freeText;
            }
            this.$router.push({ path: '/all', query });
            //http://localhost:3000/jobs/all?location=Lakewood,NJ
        },
        locationChanged() {
            this.setLocation();
        },
        async setLocation() {
            const place = this.autocomplete.getPlace();
            if (place.address_components) {
                this.location = place.formatted_address;;
            }
        },
    },
    mounted() {
        if (process.browser) {
            require("bootstrap-select");
            $(".selectpicker").selectpicker("refresh");
        }
        var isMobile = this.isMobile;
        // this.$initAutocomplete('autocomplete-input-location', {
        //     types: ["geocode"],
        //     language: "en",
        //     componentRestrictions: { country: ["us", "il"] }
        // }).then(autocomplete => {
        //     if (autocomplete) {
        //         this.autocomplete = autocomplete;
        //         this.autocomplete.addListener('place_changed', this.locationChanged);
        //     }
        //     setTimeout(function () {
        //         var margin = 9;
        //         if (isMobile)
        //             margin = 0;
        //         $(".pac-container").prependTo("#autocomplete-container").css("margin-top", margin + "px");
        //     }, 300);
        // });
    }
}
</script>

<style lang="scss" scoped></style>