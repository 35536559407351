import {
  deleteResumeFile,
  getResumeFiles,
  getUser,
  setDefaultResumeFile,
  updatePassword,
  updateUser,
  getUserAppliedDate,
  createResetToken,
  checkToken,
  getUserStatistics,
  checkActivateAdToken,
  createConnectUserFromRobotAd
} from "../services/api/auth";

export const MUTATIONS = {
  IS_MOBILE: "isMobile",
  LOGGED_USER: "loggedInUser",
  SET_RESUME_FILE_TO_APPLY: "setResumeFileToApply"
};

export const ACTIONS = {
  INIT: "nuxtServerInit",
  LOGGED_USER: "loggedInUser",
  UPDATE_USER: "updateUser",
  GET_RESUME_FILES: "getResumeFiles",
  DELETE_RESUME_FILE: "deleteResumeFile",
  SET_DEFAULT_RESUME_FILE: "setDefaultResumeFile",
  SET_RESUME_FILE_TO_APPLY: "setResumeFileToApply",
  UPDATE_PASSWORD: "updatePassword",
  GET_USER_APPLIED_DATE: "getUserAppliedDate",
  CREATE_RESET_TOKEN: "createResetToken",
  CHECK_TOKEN: "checkToken",
  CHECK_ACTIVATE_AD_TOKEN: "checkActivateAdToken",
  GET_USER_STATISTICS: "getUserStatistics",
  CREATE_USER_FROM_ROBOT_AD: "createConnectUserFromRobotAd"
};

export const GETTERS = {
  IS_MOBILE: "isMobile",
  // IS_AUTHENTICATED: 'isAuthenticated',
  LOGGED_USER: "loggedInUser",
  RESUME_FILE_TO_APPLY: "resumeFileToApply"
};

const store = {
  state() {
    return {
      isMobile: false,
      user: [],
      resumeFileToApply: 0
    };
  },
  mutations: {
    [MUTATIONS.IS_MOBILE](state, isMobile) {
      state.isMobile = isMobile;
    },
    [MUTATIONS.LOGGED_USER](state, user) {
      state.user = user;
    },
    [MUTATIONS.SET_RESUME_FILE_TO_APPLY](state, resumeFile) {
      state.resumeFileToApply = resumeFile;
    },
  },
  actions: {
    [ACTIONS.INIT]({ commit }, { req }) {
      var isMobile = false;
      const MobileDetect = require("mobile-detect");
      const md = new MobileDetect(req.headers["user-agent"]);
      if (md.mobile()) isMobile = true;
      commit("isMobile", isMobile);
    },
    [ACTIONS.LOGGED_USER]({ commit }, user) {
      return getUser(user).then(({ data }) => {
        commit(MUTATIONS.LOGGED_USER, data.recordset[0]);
        return data;
      });
    },
    [ACTIONS.GET_RESUME_FILES]({ commit, state }, user) {
      return getResumeFiles(user).then(({ data }) => {
        const resumeFiles = data.recordset;
        //commit(MUTATIONS.LOGGED_USER, { ...state.user, resumeFiles });
        return data;
      });
    },
    [ACTIONS.GET_USER_APPLIED_DATE]({ commit, state }, user) {
      return getUserAppliedDate(user).then(({ data }) => {
        const application = data.recordset;

        return application;
      });
    },
    [ACTIONS.SET_DEFAULT_RESUME_FILE](
      { commit, state, dispatch },
      { uuid, userId }
    ) {
      return setDefaultResumeFile({ uuid, userId }).then(() => {
        dispatch(ACTIONS.GET_RESUME_FILES, { userId });
      });
    },

    [ACTIONS.SET_RESUME_FILE_TO_APPLY]({ commit }, file) {
      commit(MUTATIONS.SET_RESUME_FILE_TO_APPLY, file);
    },

    [ACTIONS.DELETE_RESUME_FILE]({ commit, state }, { uuid }) {
      return deleteResumeFile({ uuid });
    },

    [ACTIONS.CREATE_RESET_TOKEN]({ commit, state }, { email }) {
      return createResetToken({ email });
    },
    [ACTIONS.CHECK_TOKEN]({ commit, state }, { token, email }) {
      return checkToken({ token, email });
    },
    [ACTIONS.CHECK_ACTIVATE_AD_TOKEN]({ commit, state }, { token, jobId }) {
      return checkActivateAdToken({ token, jobId });
    },
    [ACTIONS.CREATE_USER_FROM_ROBOT_AD]({ commit, state }, { jobId }) {
      return createConnectUserFromRobotAd({ jobId });
    },
    [ACTIONS.UPDATE_USER]({ commit }, user) {
      return updateUser(user).then(({ data, token }) => {

        commit(MUTATIONS.LOGGED_USER, data.recordset);
        return { recordset: data.data.recordset, token: token };
      });
    },
    [ACTIONS.UPDATE_PASSWORD]({ dispatch }, auth) {
      return updatePassword(auth).then(() => {
        dispatch(ACTIONS.LOGGED_USER, auth);
        return;
      });
    },
    [ACTIONS.GET_USER_STATISTICS]({ dispatch }, data) {
      return getUserStatistics(data).then(({ data }) => {
        return data;
      });
    },
  },
  getters: {
    [GETTERS.IS_MOBILE](state) {
      if (process.browser) {
        const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
        return width < 990;
      }

      return state.isMobile;
    },
    // [GETTERS.IS_AUTHENTICATED](state) {
    //     return true;
    // },
    [GETTERS.LOGGED_USER](state) {
      return true; // PLEASE CHECK THE CODE !!!!!
      //  return state.auth.user
    },

    [GETTERS.RESUME_FILE_TO_APPLY](state) {
      return state.resumeFileToApply; // PLEASE CHECK THE CODE !!!!!
      //  return state.auth.user
    },
  },
};

export default store;
