<template>
    <div class="small-footer margin-top-15">
        <div class="small-footer-copyrights">
            © 2022 <strong>Macher</strong>. All Rights Reserved.
        </div>
        <ul class="footer-social-links">
            <li v-for="item in socialMediaList" :key="item.title">
                <a :href="item.link" data-tippy-placement="top" data-tippy="" :data-original-title="item.title">
                    <component :is="item.icon"></component>
                </a>
            </li>
        </ul>
        <div class="clearfix"></div>
    </div>
</template>

<script>
// Icons
import Facebook from "icons/Facebook"
import Twitter from "icons/Twitter"
import Instagram from "icons/Instagram"
import Linkedin from "icons/Linkedin"

export default {
    components: {
        Facebook,
        Twitter,
        Instagram,
        Linkedin,
    },
    data() {
        return {
            socialMediaList: this.$store.state.base.socialMediaList
        }
    }
}
</script>
