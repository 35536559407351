<template>
    <ul :data-submenu-title="title ? title : ''">
        <template v-if="(!!pageList)">
            <SidebarItem @click.native="close()" v-if="page.userType == userType" v-for="page in pageList" :key="page.label"
                :to="page.route" :active="$route.path == page.route">
                <component :size="20" class="icon" v-if="page.icon" :is="page.icon">
                </component>
                {{ page.label }}
                <template v-if="!!page.children" #child>
                    <ul>
                        <SidebarItem @click.native="close()" v-if="pageChild.userType == userType"
                            v-for="pageChild in page.children" :key="pageChild.label" :to="pageChild.route"
                            :active="(pageChild.route == $route.path)">
                            <component :size="20" class="icon" v-if="pageChild.icon" :is="pageChild.icon">
                            </component>
                            {{ pageChild.label }}
                        </SidebarItem>
                    </ul>
                </template>
            </SidebarItem>
        </template>
    </ul>
</template>

<script>
import SidebarItem from './SidebarItem.vue';


// Icons
import ViewDashboardOutline from "icons/ViewDashboardOutline.vue";
import ForumOutline from "icons/ForumOutline.vue";
import StarOutline from "icons/StarOutline.vue";
import FileEditorOutline from "icons/FileEditOutline.vue";
import AccountGroupOutline from "icons/AccountGroupOutline.vue";
import AccountSearchOutline from "icons/AccountSearchOutline.vue";
import AccountOutline from "icons/AccountOutline.vue";
import PlusBoxMultipleOutline from "icons/PlusBoxMultipleOutline.vue";
import CreditCardOutline from "icons/CreditCardOutline.vue";
import BriefcaseOutline from "icons/BriefcaseOutline.vue";
import HomeOutline from "icons/HomeOutline.vue";
import SeatOutline from "icons/SeatOutline.vue";
import OfficeBuildingMarkerOutline from "icons/OfficeBuildingMarkerOutline.vue";
import PlusOutline from "icons/PlusOutline.vue";
import BriefcaseAccountOutline from "icons/BriefcaseAccountOutline.vue";
import Monitor from "icons/Monitor.vue";

export default {
    name: "sidebar-section",
    components: {
        SidebarItem,
        ViewDashboardOutline,
        ForumOutline,
        StarOutline,
        FileEditorOutline,
        AccountGroupOutline,
        AccountSearchOutline,
        AccountOutline,
        PlusBoxMultipleOutline,
        CreditCardOutline,
        BriefcaseOutline,
        HomeOutline,
        SeatOutline,
        OfficeBuildingMarkerOutline,
        PlusOutline,
        BriefcaseAccountOutline,
        Monitor
    },
    // data() {
    //     return {
    //         userType: this.$auth.$state.user.userType
    //     }
    // },
    computed: {
        userType() {
            return this.$auth.$state.user.userType;
        }
    },
    props: {
        title: {
            type: String,
        },
        pageList: {
            type: Array
        }
    },
    methods: {
        close() {
            this.$emit('close');
        }

    }
}
</script>

<style lang="scss" scoped>
.icon {
    position: relative;
    bottom: -3px;
}
</style>