
import axios from "axios";
//var turf = require('@turf/turf');
export default ({ app }, inject) => {
    inject('global', {
        facebookKey: "836797929683024",
        googleKey: "194392922882-jkdlkc4n44036jh97j2foatcaidh68of.apps.googleusercontent.com",
        googleMapsKey: "AIzaSyBrTrorHTE-6cPNubZrc0OWVfmf9-4osaw",

        async getLocationPolygon(address, radius) {
            // var turf = require('@turf/turf');
            let apiPath = "https://nominatim.openstreetmap.org/search.php";
            let params = {
                q: address,
                polygon_geojson: 1,
                format: "json",
            };

            let headers = {
                'User-Agent': 'MacherUSA.com/1.0 (info@macherusa.com)'
            };
            try {

                var response = await axios.get(apiPath, { params: params, headers: headers });
            }
            catch (error) {
                console.error("Failed to fetch location polygon:", error);
                // Handle the error appropriately
                return [];
            }

            if (!response.data || response.data.length == 0)
                return [];

            let geojson = response.data[0].geojson;
            let geometryType = geojson.type;
            let coordinates = geojson.coordinates;
            let bufferedGeometries = [];
            let bufferedType = geometryType; // Initialize bufferedType with the original type
            if (!radius)
                radius = 1;

            if (radius > 0) {
                switch (geometryType) {
                    case 'Polygon':
                        // Handle Polygon
                        var poly = turf.polygon(coordinates);
                        var bufferedPolygon = turf.buffer(poly, radius, { units: "miles" });
                        if (bufferedPolygon.geometry) {
                            bufferedGeometries.push(bufferedPolygon.geometry.coordinates);
                        }
                        break;
                    case 'MultiPolygon':
                        // Handle MultiPolygon
                        coordinates.forEach((element) => {
                            var multiPoly = turf.multiPolygon([element]);
                            var bufferedMultiPolygon = turf.buffer(multiPoly, radius, { units: "miles" });
                            if (bufferedMultiPolygon.geometry) {
                                bufferedGeometries.push(bufferedMultiPolygon.geometry.coordinates);
                            }
                        });
                        break;
                    case 'LineString':
                        // Handle LineString
                        var line = turf.lineString(coordinates);
                        var bufferedLine = turf.buffer(line, radius, { units: "miles" });
                        if (bufferedLine.geometry) {
                            bufferedGeometries.push(bufferedLine.geometry.coordinates);
                        }
                        bufferedType = 'Polygon'; // After buffering, the type is Polygon

                        break;
                    case 'Point':
                        // Handle Point
                        var point = turf.point(coordinates);
                        var bufferedPoint = turf.buffer(point, radius, { units: "miles" });
                        if (bufferedPoint.geometry) {
                            bufferedGeometries.push(bufferedPoint.geometry.coordinates);
                        }
                        bufferedType = 'Polygon'; // After buffering, the type is Polygon

                        break;
                    // Add cases for other types if needed
                }
            } else {
                // If no buffer is required, just return the original coordinates
                bufferedGeometries.push(coordinates);
            }

            return bufferedGeometries;
        }


    }
    )
}
//google geo filter
//new function
