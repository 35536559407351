<template>
  <div>
    <!-- Wrapper / Start -->
    <search v-if="!isHomePage() && $router.currentRoute.name" />
    <div id="wrapper">
      <TheHeader></TheHeader>
      <div class="clearfix"></div>
      <div class="">
        <nuxt />
      </div>

      <TheFooter></TheFooter>
    </div>
  </div>
  <!-- Wrapper / End -->
</template>
<script>
import TheFooter from "~/components/layout/TheFooter.vue";
import TheHeader from "~/components/layout/TheHeader.vue";

export default {
  components: {
    TheFooter,
    TheHeader,
  },
};
</script>
