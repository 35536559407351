var baseUrl = process.env.baseUrl;
import axios from "axios";
var dataIndex = {
  featuredJobs: 0,
  popularCategories: 1,
  counters: 2,
  topLocations: 3,
};
const store = {
  state() {
    return {
      isHomePageListingsLoaded: false,
      featuredJobs: [],
      counters: [],
      popularCategories: [],
      topLocations: [],
      socialMediaList: [
        {
          title: "Facebook",
          icon: "Facebook",
          link: "https://www.facebook.com/themacher",
        },
        {
          title: "Twitter",
          icon: "Twitter",
          link: "https://twitter.com/FrumJewishJobs",
        },
        {
          title: "Instagram",
          icon: "Instagram",
          link: "https://www.instagram.com/themacherusa/",
        },
        {
          title: "Linkedin",
          icon: "Linkedin",
          link: "https://www.linkedin.com/company/macher-usa",
        },
      ],
      pageList: [
        {
          title: "For Candidates",
          linkList: [
            {
              name: "Browse Jobs",
              to: "/all",
            },
            {
              name: "Favorites",
              to: "/dashboard/favourites",
            },
            {
              name: "Add Resume",
              to: "/dashboard/me?resume=upload",
            },
            {
              name: "Rewrite Resume",
              to: "/resume-pricing",
            },
            {
              name: "Job Alerts - Coming soon!",
              to: "#",
            },
          ],
        },
        {
          title: "For Employers",
          linkList: [
            {
              name: "Browse Candidates - Coming soon!",
              to: "#",
            },
            {
              name: "Post a Job",
              to: "/pricing",
            },

            {
              name: "Plans & Pricing",
              to: "/pricing",
            },
          ],
        },
        {
          title: "Helpful Links",
          linkList: [
            {
              name: "About",
              to: "/about",
            },
            {
              name: "Contact",
              to: "/contact",
            },
            {
              name: "Privacy Policy",
              to: "/privacy",
            },
            {
              name: "Terms of Use",
              to: "/terms",
            },
            {
              name: "FAQ",
              to: "/faq",
            },
          ],
        },
        {
          title: "Account",
          linkList: [
            {
              name: "Log in",
              to: "/login",
            },
            {
              name: "Account",
              to: "/dashboard",
            },
          ],
        },
      ],
    };
  },
  mutations: {
    setFeaturedJobs(state, data) {
      state.featuredJobs = data;
      state.isHomePageListingsLoaded = true;
    },
    setPopularCategories(state, data) {
      state.popularCategories = data;
    },
    setCounters(state, data) {
      state.counters = data;
    },
    setTopLocations(state, data) {
      // const locations = data.map(function (row) {
      //   return { name: row.name, value: row.longName };
      // });
      state.topLocations = data;
    },
  },
  actions: {
    async getHomePageListings({ commit }) {
      return axios
        .post(process.env.fullBaseApi + "/homepage-listings")
        .then((res) => {
          if (!res.data || !res.data.data) return;
          var data = res.data.data.recordsets;
          commit("setFeaturedJobs", data[dataIndex.featuredJobs]);
          commit("setPopularCategories", data[dataIndex.popularCategories]);
          commit("setCounters", data[dataIndex.counters][0]);
          commit("setTopLocations", data[dataIndex.topLocations]);
        });
    },

    async subscribeToMailerLite({ commit, dispatch }, data) {
      return axios
        .post(process.env.fullBaseApi + "/mailerlite-subscribe", data)
        .then((res) => {
          return res;
        });
    },

    async validateCoupon({ commit }, data) {
      return axios
        .post(process.env.fullBaseApi + "/validate-coupon", data)
        .then((res) => {
          return res.data;
        });
    },

  },
};

export default store;
