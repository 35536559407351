<template>
  <div class="dashboard-sidebar">
    <div class="dashboard-sidebar-inner" data-simplebar="init">
      <div class="simplebar-track vertical">
        <div class="simplebar-scrollbar"></div>
      </div>
      <div class="simplebar-track horizontal">
        <div class="simplebar-scrollbar"></div>
      </div>
      <div class="simplebar-scroll-content">
        <div class="simplebar-content">
          <div class="dashboard-nav-container">
            <!-- Responsive Navigation Trigger -->
            <a href="#" class="dashboard-responsive-nav-trigger" ref="dashboardTrigger">
              <span class="hamburger hamburger--collapse">
                <span class="hamburger-box">
                  <span class="hamburger-inner"></span>
                </span>
              </span>
              <span class="trigger-title">Menu</span>
            </a>

            <!-- Navigation -->
            <div class="dashboard-nav">
              <div class="dashboard-nav-inner">
                <SidebarSection @close="closeMenu()" v-if="section.userType == userType"
                  v-for="section in dashboardPageList" :key="section.section" :title="section.section"
                  :pageList="section.pageList">
                </SidebarSection>
              </div>
            </div>
            <!-- Navigation / End -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarSection from "./sidebar/SidebarSection.vue";

export default {
  components: { SidebarSection },
  data() {
    return {
      dashboardPageList: [
        {
          section: "Start",
          pageList: [
            {
              label: "Dashboard",
              icon: "ViewDashboardOutline",
              route: "/dashboard/",
              userType: 1
            },

          ],
          userType: 1
        },

        {
          section: "Start",
          pageList: [

            {
              label: "Dashboard",
              icon: "ViewDashboardOutline",
              route: "/dashboard/",
              userType: 2
            },
          ],
          userType: 2
        },

        {
          section: "Organize and manage",
          pageList: [
            {
              label: "Post ad",
              icon: "PlusOutline",
              route: "/dashboard/job",
              userType: 2
            },
            {
              label: "Manage ads",
              icon: "FileEditorOutline",
              route: "/dashboard/manage-ads",
              userType: 2

            },
            {
              label: "Manage candidates",
              icon: "AccountGroupOutline",
              route: "/dashboard/manage-candidate",
              userType: 2

            },
          ],
          userType: 2
        },
        {
          section: "Account",
          pageList: [
            {
              label: "Profile",
              icon: "AccountOutline",
              route: "/dashboard/me",
              userType: 1
            },
            {
              label: "Resume Rewriting",
              icon: "FileEditorOutline",
              route: "/resume-pricing",
              userType: 1
            },
            {
              label: "Favorites Jobs",
              icon: "StarOutline",
              route: "/dashboard/favourites",
              userType: 1
            },
            {
              label: "Applied Jobs",
              icon: "BriefcaseOutline",
              route: "/dashboard/applied-jobs",
              userType: 1
            },

          ],
          userType: 1
        },
        {
          section: "Account",
          pageList: [

            {
              label: "Profile",
              icon: "AccountOutline",
              route: "/dashboard/me",
              userType: 2
            },

            {
              label: "Ads credit",
              icon: "PlusBoxMultipleOutline",
              route: "/dashboard/credits",
              userType: 2
            }, {
              label: "Invoices",
              icon: "CreditCardOutline",
              route: "/dashboard/invoices",
              userType: 2
            },

          ],
          userType: 2
        },
      ],
    };
  },
  computed: {
    userType() {
      return this.$auth.$state.user.userType;
    }
  },
  methods: {
    closeMenu() {
      this.$refs.dashboardTrigger.click();
    }
  }
};
</script>

<style lang="scss" scoped></style>
