const store = {
  state() {
    return {
      selectedPackage: null,
    };
  },
  mutations: {
    setSelectedPackage(state, selectedPackage) {
      state.selectedPackage = selectedPackage;
    },
  },
};
export default store;
