import {
  getJobsById,
  getStatuses,
  getCandidates,
  deleteCandidate,
  updateCandidate,
} from "../../services/api/candidates";

export const MUTATIONS = {
  SET_JOBS: "setJobs",
  SET_CANDIDATES: "setCandidates",
  SET_STATUSES: "setStatuses",
  SET_NOTE: "updateNote",
  SET_STATUS: "setStatus",

};

export const ACTIONS = {
  GET_JOBS: "getJobs",
  GET_STATUSES: "getStatuses",
  GET_CANDIDATES: "getCandidates",
  DELETE_CANDIDATES: "deleteCandidate",
  UPDATE_CANDIDATES: "updateCandidate",
};

const store = {
  state() {
    return {
      allJobs: [],
      allCandidates: [],
      allStatuses: [],
      loading: true,
    };
  },
  mutations: {
    [MUTATIONS.SET_JOBS](state, allJobs) {
      state.allJobs = allJobs;
    },
    [MUTATIONS.SET_CANDIDATES](state, allCandidates) {
      state.allCandidates = allCandidates;
    },
    [MUTATIONS.SET_STATUSES](state, allStatuses) {
      state.allStatuses = allStatuses;
    },

    [MUTATIONS.SET_NOTE](state, payload) {
      const indexToUpdate = state.allCandidates.findIndex(
        (item) => item.id === payload.id
      );
      if (indexToUpdate !== -1) {
        state.allCandidates[indexToUpdate].note = payload.newNote;
      }
    },

    [MUTATIONS.SET_STATUS](state, status) {

      const indexToUpdate = state.allCandidates.findIndex(
        (item) => item.id === status.id
      );
      if (indexToUpdate !== -1) {
        state.allCandidates[indexToUpdate].status = status.status;
        state.allCandidates[indexToUpdate].s_id = status.s_id;
      }
    },
  },
  actions: {
    [ACTIONS.GET_JOBS]({ commit }, { userId }) {
      return getJobsById({ userId }).then(({ data }) => {
        const {
          recordset: [...allJobs],
        } = data;
        commit(MUTATIONS.SET_JOBS, allJobs);
        return allJobs;
      });
    },
    [ACTIONS.GET_STATUSES]({ commit }, { userId }) {
      return getStatuses({ userId }).then(({ data }) => {
        const {
          recordset: [...allStatuses],
        } = data;
        commit(MUTATIONS.SET_STATUSES, allStatuses);
        return allStatuses;
      });
    },
    [ACTIONS.GET_CANDIDATES]({ commit }, { userId }) {
      return getCandidates({ userId }).then(({ data }) => {
        const {
          recordset: [...allCandidates],
        } = data;
        commit(MUTATIONS.SET_CANDIDATES, allCandidates);
        return allCandidates;
      });
    },
    [ACTIONS.DELETE_CANDIDATES]({ commit }, { userId }) {
      return deleteCandidate({ userId }).then(({ data }) => {
        return true;
      });
    },
    [ACTIONS.UPDATE_CANDIDATES]({ commit }, data) {
      return updateCandidate(data).then((res) => {
        return true;
      });
    },
  },
};

export default store;
