// server-middleware/resume.js
const fs = require('fs');
const path = require('path');

export default (req, res, next) => {
    const match = req.url.match(/\/resume\/([a-zA-Z0-9_-]+\.(pdf|doc))$/);

    if (match) {
        const filename = match[1];
        const filePath = path.resolve(__dirname, `../static/resume/${filename}`);

        if (!fs.existsSync(filePath)) {
            return res.status(404).end('File not found');
        }

        res.setHeader('Content-Disposition', `attachment; filename="${filename}"`);
        res.setHeader('Content-Type', filename.endsWith('.pdf') ? 'application/pdf' : 'application/msword');

        return fs.createReadStream(filePath).pipe(res);
    }

    next();
};
