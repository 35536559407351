<template>
    <li>
        <RouterLink :to="to" :class="isActive ? 'current' : ''">{{ name }}</RouterLink>
        <slot></slot>
    </li>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: "Item Name"
        },
        to: {
            type: String,
            default: "#"
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },


    mounted() {

        document.addEventListener('mmenu-link-clicked', (e) => {
            var originalUrl = e.detail.url;
            var modifiedUrl = originalUrl.replace('/jewish-jobs', '');
            this.$router.push(modifiedUrl);
        });
    },
    beforeDestroy() {
        document.removeEventListener('mmenu-link-clicked', this.handleMmenuLinkClick);
    }
}
</script>
