export default ({ app }) => {
    if (process.client) {
        var scrollSpeed = 500; // how slow / fast you want the button to scroll to top.

        window.onNuxtReady(() => {
            app.router.afterEach(() => {
                $('html, body').animate({ scrollTop: 0 }, scrollSpeed);

                //window.scrollTo(0, 0);
            });
        });
    }
};
