<template>
    <div id="footer">
        <!-- Footer Top Section -->
        <div class="footer-top-section">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <!-- Footer Rows Container -->
                        <div class="footer-rows-container">
                            <!-- Left Side -->
                            <div class="footer-rows-left">
                                <div class="footer-row">
                                    <div class="footer-row-inner footer-logo">
                                        <img alt src="/images/logo/logo-white.png" />
                                    </div>
                                </div>
                            </div>

                            <!-- Right Side -->
                            <div class="footer-rows-right">
                                <!-- Social Icons -->
                                <div class="footer-row">
                                    <div class="footer-row-inner">
                                        <ul class="footer-social-links">
                                            <li v-for="item in socialMediaList" :key="item.title">
                                                <a :href="item.link" :title="item.title">
                                                    <component :is="item.icon"></component>
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Footer Rows Container / End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer Top Section / End -->

        <!-- Footer Middle Section -->
        <div class="footer-middle-section">
            <div class="container">
                <div class="row">
                    <!-- Links -->
                    <FooterLink v-for="page in pageList" :key="page.title" :to="page.title" :title="page.title"
                        :linkList="page.linkList">
                    </FooterLink>

                    <!-- Newsletter -->
                    <div class="col-xl-4 col-lg-4 col-md-12">
                        <h3>
                            <EmailOutline class="icon" /> Sign Up For a Newsletter
                        </h3>
                        <p>
                            Weekly breaking news, analysis and cutting edge advices on job
                            searching.
                        </p>
                        <form>
                            <div class="newsletter">
                                <input name="email" type="email" placeholder="Enter your email address" v-model="email" />
                                <button @click.prevent="addSubscriber">
                                    <i>
                                        <ArrowRight class="icon-arrow" />
                                    </i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer Middle Section / End -->

        <!-- Footer Copyrights -->
        <div class="footer-bottom-section">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        © 2024
                        <strong>Macher Jobs</strong>. All Rights Reserved.
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer Copyrights / End -->
    </div>
</template>

<script>

// Icon Components
import Facebook from "icons/Facebook"
import Twitter from "icons/Twitter"
import Instagram from "icons/Instagram"
import Linkedin from "icons/Linkedin"
import EmailOutline from "icons/EmailOutline"
import ArrowRight from "icons/ArrowRight"
import FooterLink from './FooterLink.vue'
export default {
    components: {
        Facebook,
        Twitter,
        Instagram,
        Linkedin,
        EmailOutline,
        ArrowRight,
        FooterLink
    },
    methods: {
        addSubscriber: function () {
            var self = this;
            $nuxt.$root.$loading.start();

            this.$nextTick(function () {
                self.$store.dispatch('base/subscribeToMailerLite', { "email": this.email, "resubscribe": true })

                    //, "type": "null", "name": "Diti Pniel" })
                    .then(function (response) {
                        $nuxt.$root.$loading.finish();

                        console.log(response.data.error);
                        if (response.data.success)
                            self.$toasted.global.subscribedSuccessful();
                        else
                            self.$toasted.global.subscribedError();


                    })
                    .catch(function (error) {
                        self.$sentry.captureException(error);
                    });
            })
        },
    },
    data() {
        return {
            socialMediaList: this.$store.state.base.socialMediaList,
            pageList: this.$store.state.base.pageList,
            email: ""
        }
    },
}
</script>

<style lang="scss" scoped>
.icon {
    position: relative;
    bottom: -5px;
}

.icon-arrow {
    position: relative;
    bottom: -8px;
}
</style>