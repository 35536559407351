export default ({ app }) => {
    if (process.client) {

        // GetSiteControl Script
        // let gscScript = document.createElement('script');
        // gscScript.src = '//widgets.getsitecontrol.com/164388/script.js';
        // gscScript.async = true;
        // document.head.appendChild(gscScript);

        // HubSpot Script
        let hsScript = document.createElement('script');
        hsScript.src = '//js.hs-scripts.com/2472350.js';
        hsScript.async = true;
        hsScript.defer = true;
        document.head.appendChild(hsScript);

        // Google Analytics GA4 Script
        let ga4Script = document.createElement('script');
        ga4Script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID}`;
        ga4Script.async = true;
        document.head.appendChild(ga4Script);

        ga4Script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID);
        }

        // MailerLite Universal Script
        let mlScript = document.createElement('script');
        mlScript.async = true;
        document.head.appendChild(mlScript);
        mlScript.onload = () => {
            (function (m, a, i, l, e, r) {
                m['MailerLiteObject'] = e;
                function f() {
                    var c = { a: arguments, q: [] };
                    var r = this.push(c);
                    return "number" != typeof r ? r : f.bind(c.q);
                }
                f.q = f.q || [];
                m[e] = m[e] || f.bind(f.q);
                m[e].q = m[e].q || f.q;
                r = a.createElement(i);
                var _ = a.getElementsByTagName(i)[0];
                r.async = 1;
                r.src = l + '?v' + (~~(new Date().getTime() / 1000000));
                _.parentNode.insertBefore(r, _);
            })(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');

            var ml_account = ml('accounts', process.env.MAILER_LITE_ACCOUNT_ID, process.env.MAILER_LITE_ACCOUNT_PASSWORD, 'load');
        };
        // Set the source of the script after defining the onload function
        mlScript.src = 'https://static.mailerlite.com/js/universal.js';
    }
};
