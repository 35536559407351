import { request } from "./common";

const API_URL = `${process.env.API_URL || "/api"}`;

/**
 * Helper method to get all job post by user_id
 * @param {*} data
 * @param {*} options
 * @returns
 */

/**
 * Helper method to send email
 * @param {*} data
 * @param {*} options
 * @returns
 */
export function sendEmail(data = {}, options = {}) {
  data.from = {
    email: "contact@macherusa.com",
    name: "Macher Team",
  };
  // console.log(data);
  return request(`${API_URL}/send-email`, {
    method: "POST",
    data,
    ...options,
  });
}


