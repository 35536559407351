import {
    getJobCategories,
    getJobSubCategories,
    getStates,
    getCities,
    createJobPost,
    getJobAdById,
    updateJobPost,
} from "../../services/api/jobs";

export const MUTATIONS = {
    SET_CATEGORIES: 'setCategories',
    SET_SUB_CATEGORIES: 'setSubCategories',
    SET_STATES: 'setStates',
    SET_CITIES: 'setCities',
    SET_JOB_AD: 'setJobAd'
};
export const ACTIONS = {
    GET_CATEGORIES: 'getCategories',
    GET_SUB_CATEGORIES: 'getSubCategories',
    GET_STATES: 'getStates',
    GET_CITIES: 'getCities',
    CREATE_JOB_POST: 'createJobPost',
    GET_JOB_AD_BY_ID: 'getJobAdById',
    UPDATE_JOB_AD_BY_ID: 'updateJobAdById',
};

const store = {
    state() {
        return {
            categories: [],
            subCategories: [],
            states: [],
            cities: [],
            jobAd: {}
        }
    },
    mutations: {
        [MUTATIONS.SET_CATEGORIES](state, categories) {
            state.categories = categories;
        },
        [MUTATIONS.SET_SUB_CATEGORIES](state, subCategories) {
            state.subCategories = subCategories;
        },
        [MUTATIONS.SET_STATES](state, states) {
            state.states = states;
        },
        [MUTATIONS.SET_CITIES](state, cities) {
            state.cities = cities;
        },

        [MUTATIONS.SET_JOB_AD](state, jobAd) {
            state.jobAd = jobAd;
        },
    },
    actions: {
        [ACTIONS.GET_JOB_AD_BY_ID]({ commit }, data) {
            return getJobAdById(data).then(({ data }) => {
                const { recordset: { 0: jobAd } } = data;
                commit(MUTATIONS.SET_JOB_AD, jobAd);
                return jobAd;
            })
        },
        [ACTIONS.GET_CATEGORIES]({ commit }) {
            return getJobCategories().then(({ data }) => {
                const { recordset: categories = [] } = data;
                commit(MUTATIONS.SET_CATEGORIES, categories);
                return categories;
            })
        },
        [ACTIONS.GET_SUB_CATEGORIES]({ commit }, { mainCategoryId }) {
            return getJobSubCategories({ mainCategoryId }).then(({ data }) => {
                const { recordset: subCategories = [] } = data;
                commit(MUTATIONS.SET_SUB_CATEGORIES, subCategories);
                return subCategories;
            })
        },
        [ACTIONS.GET_STATES]({ commit }) {
            return getStates().then(({ data }) => {
                const { recordset: states = [] } = data;
                commit(MUTATIONS.SET_STATES, states);
                return states;
            })
        },
        [ACTIONS.GET_CITIES]({ commit }, { stateId }) {
            return getCities({ stateId }).then(({ data }) => {
                const { recordset: cities = [] } = data;
                commit(MUTATIONS.SET_CITIES, cities);
                return cities;
            })
        },

        [ACTIONS.CREATE_JOB_POST]({ commit }, data) {
            return createJobPost(data);
        },
        [ACTIONS.UPDATE_JOB_AD_BY_ID]({ dispatch }, data) {
            return updateJobPost(data);
        }
    }
};

export default store;
