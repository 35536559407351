const middleware = {}

middleware['checkout'] = require('..\\middleware\\checkout.js')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['redirect'] = require('..\\middleware\\redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['resume'] = require('..\\middleware\\resume.js')
middleware['resume'] = middleware['resume'].default || middleware['resume']

export default middleware
