import { request } from "./common";

const API_URL = `${process.env.API_URL || "/api"}`;

/**
 * @param {*} data
 * @returns
 */

export function getJobsById(data, options = {}) {
  return request(`${API_URL}/posts/get-jobs-by-user`, {
    method: "POST",
    data,
    ...options,
  });
}

export function getCandidates(data, options = {}) {
  return request(`${API_URL}/posts/get-candidates-by-user`, {
    method: "POST",
    data,
    ...options,
  });
}

export function getStatuses(data, options = {}) {
  return request(`${API_URL}/posts/get-applicant-statuses`, {
    method: "POST",
    data,
    ...options,
  });
}

export function deleteCandidate(data, options = {}) {
  return request(`${API_URL}/posts/candidate/delete`, {
    method: "POST",
    data,
    ...options,
  });
}

export function updateCandidate(data, options = {}) {
  return request(`${API_URL}/posts/candidate/update`, {
    method: "POST",
    data,
    ...options,
  });
}
