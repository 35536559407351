import { request } from "./common";

const API_URL = `${process.env.API_URL || "/api"}`;

/**
 * Helper method to get all invoices by user Id
 * @param {*} data
 * @param {*} options
 * @returns
 */

export function getInvoicesByUserId(data, options = {}) {
  return request(`${API_URL}/invoices`, {
    method: "POST",
    data,
    ...options,
  });
}
