<template>
    <div :class="{
        'user-avatar': true,
        'candidate-avatar': isCandidate,
        'status-online': !isCandidate
    }">
        <img :src="computedImgUrl" @error="handleImageError" alt="">
    </div>
</template>

<script>
export default {

    props: {
        profilePic: {
            type: String,
            default: ''
        },
        logo: {
            type: String,
            default: ''
        },
        isCandidate: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedImgUrl() {
            if (this.profilePic) {
                return `/uploads/profile/${this.profilePic}`;
            } else if (this.logo) {
                return `/uploads/logo/${this.logo}`;
            } else {
                return '/images/user-avatar-placeholder.png';
            }
        }
    },
    methods: {
        handleImageError() {
            this.$el.querySelector('img').src = '/images/user-avatar-placeholder.png';
        }
    }
}
</script>
