import { request } from "./common";

const API_URL = `${process.env.API_URL || "/api"}`;

/**
 * Helper method to get user details based on the params
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getUser(data, options = {}) {
  return request(`${API_URL}/auth/user/me`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to update user profile
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updateUser(data, options = {}) {
  return request(`${API_URL}/auth/user/me/update`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to check if email exists based on the params
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function createResetToken(data, options = {}) {
  return request(`${API_URL}/auth/user/reset-token`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to upload user resume
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function uploadFile(data, options = {}) {
  return request(`${API_URL}/auth/user/me/upload`, {
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "multipart/form-data",
    },
    ...options,
  });
}

/**
 * Helper method to upload profile image file
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function uploadProfileImage(data, options = {}) {
  return request(`${API_URL}/auth/user/me/upload/profileimage`, {
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "multipart/form-data",
    },
    ...options,
  });
}

/**
 * Helper method to delete profile image file
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deleteProfileImage(data, options = {}) {
  return request(`${API_URL}/auth/user/me/upload/profileimage`, {
    method: "DELETE",
    data,
    ...options,
  });
}

/**
 * Helper method to upload company logo file
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function uploadCompanyLogo(data, options = {}) {
  return request(`${API_URL}/auth/user/me/upload/companylogo`, {
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "multipart/form-data",
    },
    ...options,
  });
}

/**
 * Helper method to delete profile image file
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deleteCompanyLogo(data, options = {}) {
  return request(`${API_URL}/auth/user/me/upload/companylogo`, {
    method: "DELETE",
    data,
    ...options,
  });
}

/**
 * Helper method to get resume files based on userId
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getResumeFiles(data, options = {}) {
  return request(`${API_URL}/auth/user/me/resume`, {
    method: "POST",
    data,
    ...options,
  });
}

export function getUserAppliedDate(data, options = {}) {
  return request(`${API_URL}/auth/user/me/appliedDate`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to delete resume file based on UUID
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function deleteResumeFile(data, options = {}) {
  return request(`${API_URL}/auth/user/me/resume/delete`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to delete resume file based on UUID
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function setDefaultResumeFile(data, options = {}) {
  return request(`${API_URL}/auth/user/me/resume/default`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to update user login password
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function updatePassword(data, options = {}) {
  return request(`${API_URL}/auth/user/password/update`, {
    method: "POST",
    data,
    ...options,
  });
}
/**
 * Helper method to check user token
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function checkToken(data, options = {}) {
  return request(`${API_URL}/auth/user/password/check-token`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to check user token
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function checkActivateAdToken(data, options = {}) {
  return request(`${API_URL}/auth/check-activate-ad-token`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to check user token
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function createConnectUserFromRobotAd(data, options = {}) {
  return request(`${API_URL}/auth/create-user-from-robot-ad`, {
    method: "POST",
    data,
    ...options,
  });
}

/**
 * Helper method to update user totals statistics
 * @param data
 * @param options
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getUserStatistics(data, options = {}) {
  return request(`${API_URL}/auth/user/me/statistics`, {
    method: "POST",
    data,
    ...options,
  });
}
