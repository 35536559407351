<template>
  <div id="wrapper" class="mm-page mm-slideout">
    <TheHeader></TheHeader>
    <div class="clearfix"></div>
    <div class="dashboard-container">
      <!-- Sidebar -->
      <TheSidebar></TheSidebar>
      <!-- Dashboard content -->
      <ContentWrapper>
        <!-- Dashboard Inner Content -->
        <nuxt />
        <!-- Footer -->
        <!-- <Footer></Footer> -->
        <!-- Footer / End -->

      </ContentWrapper>
    </div>
  </div>
</template>
<script>
import TheHeader from '~/components/layout/TheHeader.vue';
import Footer from '~/components/dashboard/Footer.vue';
import ContentWrapper from '~/components/dashboard/ContentWrapper.vue';
import TheSidebar from '~/components/layout/TheSidebar.vue';

// Data
// import { mapGetters } from "vuex";

export default {
  components: {
    TheHeader,
    Footer,
    ContentWrapper,
    TheSidebar,
  }
};
</script>

